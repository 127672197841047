import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import firebase from "firebase/app";
import "firebase/analytics";
import "firebase/performance";
import loadable from "@loadable/component";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import "./index.css";
import { Amplify } from "aws-amplify";
import awsconfig from "../src/aws-exports";
import { sentryConfig } from "../src/config";
import { setAnalyticsSupport } from "../src/analytics/libs/analytics";
import FirebaseContextProvider from "../src/analytics/FirebaseProvider";
import CleverTapContextProvider from "../src/analytics/CleverTapProvider";

const App = loadable(() => import("./App"));

Sentry.init(sentryConfig);

/** Transforms sentry errors to firebase log types */
const firebaseToSentryLevel = {
  error: "error" as const,
  warn: "warning" as const,
  info: "info" as const,
  debug: "debug" as const,
  verbose: "log" as const,
  silent: "log" as const,
};

/** Global listener for firebase to listen to sentry errors */
firebase.onLog(
  ({ args, level }) => {
    Sentry.captureException(args, {
      level: Sentry.Severity.fromString(firebaseToSentryLevel[level]),
    });
  },
  { level: "warn" }
);

(async () => {
  const isAnalyticsSupported = await firebase.analytics.isSupported();
  setAnalyticsSupport(isAnalyticsSupported);
  if (!window.location.hostname) {
    if (isAnalyticsSupported) {
      firebase.analytics().setAnalyticsCollectionEnabled(false);
    }
  } else {
    if (isAnalyticsSupported) {
      firebase.analytics();
    }
    firebase.performance();
  }
})();

// * custom domain name for OAuth
const updatedAwsConfig = {
  ...awsconfig,
  oauth: {
    ...awsconfig.oauth,
    domain: "auth.onward.masaischool.com",
    scope: [
      "phone",
      "email",
      "openid",
      "profile",
      "aws.cognito.signin.user.admin",
    ],
    redirectSignIn: "https://onward.masaischool.com/dashboard/",
    redirectSignOut: "https://onward.masaischool.com/dashboard/",
    responseType: "code",
  },
};

Amplify.configure(updatedAwsConfig);

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={"An error has occurred"}>
      <FirebaseContextProvider>
        <CleverTapContextProvider>
          <App />
        </CleverTapContextProvider>
      </FirebaseContextProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
