import React, { createContext, useContext, useEffect, useState } from "react";
import { firebaseConfig } from "../config";
import firebase from "firebase/app";
import "firebase/remote-config";

export type Firebase = typeof firebase;

const initialize = async () => {
  if (typeof window === "undefined") {
    return null;
  }

  if (!process.env.REACT_APP_FIREBASE_APP_ID) {
    console.error("firebase config not found.");
    return null;
  }

  // Modules
  await Promise.all([import("firebase/analytics")]);

  const app = firebase.initializeApp(firebaseConfig);

  // Initialzation
  app.analytics();

  return firebase;
};
const promise = initialize();

export const getFirebase = (): Promise<Firebase | null> => promise;

const FirebaseContext = createContext<Firebase | null>(null);

const FirebaseContextProvider: React.FC = ({ children }) => {
  const [firebase, setFirebase] = useState<Firebase | null>(null);

  useEffect(() => {
    (async () => {
      setFirebase(await promise);
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (typeof window !== "undefined" && !firebase) return null;

  return (
    <FirebaseContext.Provider value={firebase}>
      {children}
    </FirebaseContext.Provider>
  );
};

const useFirebase = (): {
  firebase: Firebase | null;
  getFirebase: () => Promise<Firebase>;
} => {
  const firebase = useContext(FirebaseContext);
  return {
    firebase,
    getFirebase: getFirebase as () => Promise<Firebase>, // Firebase instance given only in browser
  };
};

export default FirebaseContextProvider;
export { useFirebase };
