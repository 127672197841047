/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-south-1",
    "aws_cognito_identity_pool_id": "ap-south-1:0f4f0790-67db-46c5-ae06-2f23b0124ba2",
    "aws_cognito_region": "ap-south-1",
    "aws_user_pools_id": "ap-south-1_syKNcvh0Y",
    "aws_user_pools_web_client_id": "2cpgkc95e3pvf9u6dqokqfr0pd",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://mbnqqms5ezh3biyczkvk2f5zk4.appsync-api.ap-south-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-south-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "mettlCallback",
            "endpoint": "https://ynzyte4yue.execute-api.ap-south-1.amazonaws.com/main",
            "region": "ap-south-1"
        },
        {
            "name": "instamojoCallback",
            "endpoint": "https://jq0l4jkrye.execute-api.ap-south-1.amazonaws.com/main",
            "region": "ap-south-1"
        }
    ],
    "aws_user_files_s3_bucket": "admissionsbackendstoragebucket163331-main",
    "aws_user_files_s3_bucket_region": "ap-south-1"
};


export default awsmobile;
