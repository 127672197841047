import { BrowserOptions } from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

export interface SentryConfig extends BrowserOptions {
  dsn: string;
}

export const config: SentryConfig = {
  dsn: process.env.REACT_APP_SENTRY_DSN!,
  enabled: Boolean(process.env.REACT_APP_SENTRY_DSN),
  integrations: [new Integrations.BrowserTracing()],
  release: "masai-onward-frontend@" + process.env.npm_package_version,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
};
